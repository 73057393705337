*{
  padding: 0;
  margin: 0
}
.glow-on-hover {
  margin: -1vw;
  width: 19.5vw;
  height: 50px;
  border: none;
  outline: none;
  color: #fff;
  background: #111;
  cursor: pointer;
  position: relative;
  z-index: 0;
  
}

.glow-on-hover:before {
  content: '';
  background: linear-gradient(45deg, black, #f5f5f5);
  position: absolute;
  top: -2px;
  left:-2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing 20s linear infinite;
  opacity: 0;
  transition: opacity .3s ease-in-out;
  border-radius: 1px;
}

.glow-on-hover:active {
  color: #000000e6
}

.glow-on-hover:active:after {
  background: transparent;
}

.glow-on-hover:hover:before {
  opacity: 1;
}

.glow-on-hover:after {
  z-index: -1;
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background: #1c1a1ae6;
  left: 0;
  top: 0;
  border-radius: 1px;
}

@keyframes glowing {
  0% { background-position: 0 0; }
  50% { background-position: 400% 0; }
  100% { background-position: 0 0; }
}

.parent:nth-of-type(3) {
  background: #ffffff url("https://images.unsplash.com/photo-1469719847081-4757697d117a?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80") no-repeat center center fixed;
  background-size: cover;
}

.parent:nth-of-type(2) {
  background: #ffffff url("https://images.unsplash.com/photo-1622976479615-38700d470a1c?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2049&q=80") no-repeat center center fixed;
  background-size: cover;
}

.parent:nth-of-type(4) {
  background: #ffffff url("https://images.unsplash.com/photo-1492573637402-25691cd9eac2?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80") no-repeat center center fixed;
  background-size: cover;
}

.about {
  background: #ffffff url("https://images.unsplash.com/photo-1458724338480-79bc7a8352e4?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80") no-repeat center center fixed;
  background-size: cover;
  width: 100%;
  height: 150vh;
  color: #fff;
  text-shadow: 0 0 5px #000;
  font-family: tahoma;
  text-align: center;
  line-height: 180%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden
}

.top {
  /* background: #ffffff url("https://images.unsplash.com/photo-1492573637402-25691cd9eac2?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80") no-repeat center center fixed; */
  width: 100%;
  height: 150vh;
  color: #fff;
  text-shadow: 0 0 5px #000;
  font-family: tahoma;
  text-align: center;
  line-height: 180%;
  display: flex;
  align-items: ;
  justify-content: center;
  position: relative;
  overflow: hidden
}
/* .services p{
  margin: 1vw;
}

.services h2{
  margin-left: 1vw;
}

.services h1{
  margin-top: 3vw;
} */

.process {
  background: #ffffff url("https://images.unsplash.com/photo-1469719847081-4757697d117a?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80") no-repeat center center fixed;
  background-size: cover;
  width: 100%;
  height: 150vh;
  color: #fff;
  text-shadow: 0 0 5px #000;
  font-family: tahoma;
  text-align: center;
  line-height: 180%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden
}

.home {
  background: #ffffff url("https://images.unsplash.com/photo-1502921935-be74c0dc50fd?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80") no-repeat center center fixed;
  background-size: cover;
  width: 100%;
  height: 150vh;
  color: #fff;
  text-shadow: 0 0 5px #000;
  font-family: tahoma;
  text-align: center;
  line-height: 180%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden
}

.parent {
  width: 100%;
  height: 150vh;
  color: #fff;
  text-shadow: 0 0 5px #000;
  font-family: tahoma;
  text-align: center;
  line-height: 180%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden
}

.parent p {
  padding: 25px
}


#abc {
  width: 100%;
}

nav {
  width: 100%;
  height: 56px;
  background-color: #000000e6;
  line-height: 50px;
}

nav ul {
  font-size: 2.5vh;
  font-family: Arial, Helvetica, sans-serif;
  float: right;
  margin-right: 30px;
}

nav ul li{
  list-style-type: none;
  display: inline-block;
  transition: 0.7s all;
}

nav ul li:hover {
  background-color: rgb(152, 20, 20);
  transition: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}

nav ul li a {
  text-decoration: none;
  color: #fff;
  padding: 30px;
}

/* body {
  font-family: 'Signika', sans-serif;
} */
.bg-grey {
  background: #292929;
}
.logo-footer {
  margin-bottom: 40px;
}
footer {
      color: grey;
}
footer p {
font-size: 12px;
font-family: 'Roboto', sans-serif;
}
footer h6 {
font-family: 'Playfair Display', serif;
margin-bottom: 40px;
position: relative;
}
footer h6:after {
position: absolute;
content: "";
background: grey;
width: 40%;
height: 1px;
left: 0;
bottom: -20px;
}
.btn-footer {
           color: grey;
        
          text-decoration: none;
          border: 1px solid;
          border-radius: 43px;
          font-size: 13px;
          padding: 7px 30px;
          line-height: 47px;
}
.btn-footer:hover {
          
          text-decoration: none;
         
}
.form-footer input[type="text"] {
  border: none;
border-radius: 16px 0 0 16px;
outline: none;
padding-left: 10px;

}
::placeholder {
font-size: 10px;
padding-left: 10px;
font-style: italic;
}

.social .fa {
color: grey;
font-size: 22px;
padding: 10px 15px;
background: #3c3c3c;
}
footer ul li {
list-style: none;
display: block;
}
footer ul  {
padding-left: 0;
}
footer ul  li a{
text-decoration: none;
color: grey;
text-decoration:none;
}
a:hover {
text-decoration: none;
color: #f5f5f5 !important;

}
.logo-part {
border-right: 1px solid grey;
height: 100%;
}
